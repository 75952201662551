import '@/common/styles/styles.scss';

import PropTypes from 'prop-types';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';

import postLogin from '@/common/api/login/postLogin';
import Snack from '@/common/components/molecules/Snack/Snack';
import {
  GENERAL_OPTIONS,
  PRICE_AND_STOCK_PATHS,
  ROLES,
  ROLES_CHECKER,
  ROUTE_HOME,
  ROUTE_SCANBAT,
  SESSION_STORAGE_USER_SELECTED,
  SHIPPING_TYPES,
} from '@/common/constants';
import { AuthContext, useAuth } from '@/common/contexts/AuthContext';
import { useConfig } from '@/common/contexts/ConfigContext';
import formatSnakeToCamelCase from '@/common/helpers/formatSnakeToCamelCase';
import roleChecker from '@/common/helpers/roleChecker';
import useGeneralOptions from '@/common/hooks/general-options/useGeneralOptions';
import useUserCurrentInfo from '@/common/hooks/users/current/useUserCurrentInfo';
import axiosAuth, {
  interceptAuthRequests,
  interceptAuthResponses,
} from '@/common/interceptors/axios.AuthInterceptor';
import axiosPublic, {
  interceptPublicRequests,
  interceptPublicResponses,
} from '@/common/interceptors/axios.PublicInterceptor';
import SessionStorageAuth from '@/common/services/SessionStorage.auth';
import SessionStorageUser from '@/common/services/SessionStorage.user';

const AuthConsumer = ({ children }) => {
  const { apiScope } = useConfig();
  const { logout, addZergRush, removeZergRush } = useAuth();

  const requestsAuthInterceptor = interceptAuthRequests(apiScope);
  const responsesAuthInterceptor = interceptAuthResponses(
    logout,
    addZergRush,
    removeZergRush,
  );
  const requestsPublicInterceptor = interceptPublicRequests(apiScope);

  useEffect(
    () => () => {
      axiosAuth.interceptors.request.eject(requestsAuthInterceptor);
      axiosAuth.interceptors.response.eject(responsesAuthInterceptor);
      axiosPublic.interceptors.request.eject(requestsPublicInterceptor);
    },
    [
      requestsAuthInterceptor,
      responsesAuthInterceptor,
      requestsPublicInterceptor,
    ],
  );

  return children;
};

AuthConsumer.defaultProps = {};

AuthConsumer.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

const AuthProvider = ({ children }) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const authService = SessionStorageAuth.getService();
  const userService = SessionStorageUser.getService();

  const { mutateAsync, isLoading } = useMutation(postLogin);

  const checkIsLoggedIn = useCallback(
    () => !!authService.getAccessToken() && !!authService.getRefreshToken(),
    [authService],
  );

  // TODO: Désactivé pour l'instant (demande du client)
  // const checkIsCompleteProfile = useCallback(() => {
  //   const userProfile = userService.getUserDataFromToken();
  //
  //   return !(
  //     userProfile &&
  //     (!userProfile.lastname ||
  //       userProfile.lastname === '' ||
  //       userProfile.lastname === NULL_VALUE ||
  //       !userProfile.firstname ||
  //       userProfile.firstname === '' ||
  //       userProfile.firstname === NULL_VALUE ||
  //       !userProfile.email ||
  //       userProfile.email === '' ||
  //       userProfile.email === NULL_VALUE)
  //   );
  // }, [userService]);

  const [authErrors, setAuthErrors] = useState([]);
  const [isLoggedIn, setIsLoggedIn] = useState(checkIsLoggedIn());
  const prevIsLoggedInRef = useRef(isLoggedIn);
  const [logoutError, setLogoutError] = useState(false);
  // const [isCompleteProfile, setIsCompleteProfile] = useState(
  //   checkIsCompleteProfile(),
  // );
  const [user, setUser] = useState(userService.getUserDataFromToken());
  const [zergRush, setZergRush] = useState(false);
  const [isAPIDown, setIsAPIDown] = useState(false);

  const { data: currentUser, refetch: refetchUser } = useUserCurrentInfo(
    isLoggedIn,
  );

  const isScanbat = window.location.pathname.includes(ROUTE_SCANBAT);

  const { data: generalOptions } = useGeneralOptions({
    isLoggedIn: isLoggedIn && !isScanbat,
  });

  useEffect(() => {
    setUser((prevUser) => ({
      ...prevUser,
      ...userService.getUserDataFromToken(),
      ...currentUser,
    }));
  }, [currentUser, setUser, userService]);

  // Role checker
  // Is displayed if `roleChecker` is set in sessionStorage
  useEffect(() => {
    if (sessionStorage.getItem(ROLES_CHECKER) !== null) {
      roleChecker(user);
    }
  }, [user]);

  /**
   * Generate list of user accesses rules
   * @type {{}}
   */
  const userAccesses = useMemo(() => {
    const options = {};
    const shippingOptions = {};

    // Get accesses in generalOptions array
    generalOptions?.data?.forEach((item) => {
      // Check if item type equals delivery (drive, collect, delivery)
      if (item.type === GENERAL_OPTIONS.DELIVERY) {
        shippingOptions[SHIPPING_TYPES[item.code]] = item.enabled;
      } else {
        options[formatSnakeToCamelCase(item.code)] = item.enabled;
      }
    });

    // Add shipping options object to options object
    options.shippingOptions = shippingOptions;

    // Get accesses in user object
    if (user) {
      Object.keys(user)
        .filter((item) => typeof user[item] === 'boolean')
        .forEach((item) => {
          options[formatSnakeToCamelCase(item)] = user[item];
        });

      options.accessPriceFile =
        options.showPriceFile && options.hasAccessPriceFile;
      options.isShowNetPrice =
        ((options?.isShowNetPrice ?? false) &&
          (user?.functionality_accesses?.general_show_net_price ?? false)) ||
        (isScanbat &&
          (user?.role ?? ROLES.CUSTOMER_GUEST) !== ROLES.CUSTOMER_GUEST);
    }

    return options;
  }, [generalOptions, isScanbat, user]);

  const login = useCallback(
    (credentials, onSuccess) =>
      mutateAsync(credentials)
        // eslint-disable-next-line camelcase
        .then(({ data: { token, refresh_token } }) => {
          authService.setToken(token, refresh_token);

          const userData = userService.getUserDataFromToken();

          setIsLoggedIn(true);
          // setIsCompleteProfile(checkIsCompleteProfile());
          setUser(userData);
          onSuccess(userData);
        })
        .catch((error) => {
          setAuthErrors(['common:login.authentication.error']);

          throw error;
        }),
    [authService, mutateAsync, userService],
  );

  const logout = useCallback(
    (history, error) => {
      if (history) {
        history.replace(ROUTE_HOME); // To prevent re redirect on restricted page after login from admin to normal account
      }
      if (error) {
        setLogoutError(true);
      }
      authService.clearToken();
      queryClient.removeQueries();
      sessionStorage.removeItem(SESSION_STORAGE_USER_SELECTED);

      setIsLoggedIn(false);
      // setIsCompleteProfile(true);
      setUser(null);
    },
    [authService, queryClient],
  );

  useEffect(() => {
    if (!prevIsLoggedInRef.current && isLoggedIn) {
      setLogoutError(false);
    }
    prevIsLoggedInRef.current = isLoggedIn;
  }, [isLoggedIn]);
  /**
   * Call response interceptor
   */
  useEffect(() => {
    interceptPublicResponses({ isAPIDown, setIsAPIDown });
  }, [isAPIDown, logout]);

  const getMessage = useMemo(() => {
    const path = window.location.pathname.split('/');
    let message = 'general';

    if (path && path[1] && PRICE_AND_STOCK_PATHS.includes(path[1])) {
      message = 'price_and_stock';
    }

    return message;
  }, []);

  const addZergRush = useCallback(() => {
    const isZergRush = authService.getZergRush();

    authService.setZergRush(0);
    setZergRush(true);

    if (!isZergRush) {
      toast.error(
        <Snack message={t(`common:messages:zerg_rush.${getMessage}.error`)} />,
      );
    }
  }, [getMessage, authService, t]);

  const removeZergRush = useCallback(() => {
    const isZergRush = authService.getZergRush();

    authService.setZergRush(1);
    setZergRush(false);

    if (isZergRush) {
      toast.success(
        <Snack
          message={t(`common:messages:zerg_rush.${getMessage}.success`)}
        />,
      );
    }
  }, [getMessage, authService, t]);

  return (
    <AuthContext.Provider
      value={{
        ...userAccesses,
        addZergRush,
        authErrors,
        isAPIDown,
        // isCompleteProfile,
        isLoading,
        isLoggedIn,
        setIsLoggedIn,
        login,
        logout,
        logoutError,
        refetchUser,
        refreshToken: authService.getRefreshToken(),
        removeZergRush,
        user,
        zergRush,
      }}
    >
      <AuthConsumer>{children}</AuthConsumer>
    </AuthContext.Provider>
  );
};

AuthProvider.defaultProps = {};

AuthProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export default AuthProvider;
