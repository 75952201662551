import '@/common/styles/abstract/breakpoints.scss';

import breakpoints from '@/common/helpers/includeMediaExport';

export * from './images';

export const MAIN_BODY_ID = 'main-body';

export const EBAT = 'ebat';
export const CHRONO = 'chrono';
export const MATERIAUX = 'materiaux';
export const SCANBAT = 'SCANBAT';
export const ENV_LIST = [EBAT, CHRONO, MATERIAUX];

export const DATA_LAYER_NAME = 'dataLayer';
export const COOKIE_AD_PERSONALIZATION = 'ad_personalization';
export const COOKIE_AD_STORAGE = 'ad_storage';
export const COOKIE_AD_USER_DATA = 'ad_user_data';
export const COOKIE_ANALYTICS_STORAGE = 'analytics_storage';
export const COOKIE_SETUP = 'setup';
export const COOKIE_VALUE_DENIED = 'denied';
export const COOKIE_VALUE_GRANTED = 'granted';

export const ROLES_CHECKER = 'rolesChecker';

export const API_SCOPES = {
  [EBAT]: 'EB',
  [CHRONO]: 'EBC',
  [MATERIAUX]: 'LM',
};

export const TYPE_ORIGIN = {
  EB: 'EB',
  EBC: 'EBC',
  LM: 'LM',
};

const imBreakpoints = breakpoints.getAll();
// const imBreakpoints = {
//   phone: '375px',
//   tablet: '768px',
//   desktop: '1024px',
//   large: '1280px',
//   full: '1600px',
// };

// Installer default coefficient
export const DEFAULT_COEFFICENT = 100;

// VAT values
export const VAT = [
  {
    key: '5.5%',
    value: 550,
  },
  {
    key: '10%',
    value: 1000,
  },
  {
    key: '20%',
    value: 2000,
  },
];

export const VAT_OBJECT = {
  550: '5.5%',
  1000: '10%',
  2000: '20%',
};
// OBJECT price type
export const PRICE_OBJECT_TYPE = {
  CUSTOMER: 'customer_price',
  PUBLIC: 'public_price',
  WEEE_AMOUNT: 'weee_amount',
};
// Price type
export const PRICE = {
  PUBLIC: 'public',
  NET: 'net',
};

export const PRICE_TYPE = [
  {
    key: PRICE.NET,
    value: PRICE.NET,
  },
  {
    key: PRICE.PUBLIC,
    value: PRICE.PUBLIC,
  },
];

export const BREAKPOINTS = Object.keys(imBreakpoints).reduce((acc, key) => {
  acc[key] = parseInt(imBreakpoints[key].replace('px', ''), 10);
  return acc;
}, {});

export const REQUIRED = 'REQUIRED';
export const INVALID_EMAIL_ADDRESS = 'INVALID_EMAIL_ADDRESS';
export const WAREHOUSE_DIFFERENT = 'WAREHOUSE_DIFFERENT';
export const ACCESS_DENIED = 'ACCESS_DENIED';
export const ACCESS_DENIED_WAREHOUSE = 'Access denied: warehouse is blocked';
export const LOGIN_DISABLED = 'LOGIN_DISABLED';
export const SUCCESS = 200;
export const UNAUTHORIZED = 401;
export const FORBIDDEN = 403;
export const NOT_FOUND = 404;
export const NO_CONTENT = 204;
export const PARTIAL_CONTENT = 206;
export const SNACK_DELAY = 3000;
export const RECAPTCHA_ACTION = 'form';
export const DEBOUNCE_INPUT = 600;
export const DEBOUNCE_SEARCH_INPUT = 200;
export const DEBOUNCE_PERIOD = 600;
export const DEBOUNCE_WINDOW_RESIZE = 300;
export const DEBOUNCE_QUANTITY = 300; // used in quantity
export const MAX_RETRY_DELAY = 30000; // Price and Socks
export const MAX_RETRY_LIMIT = 4; // Context Stocks
export const VALID_TOKEN = 'VALID_TOKEN';
export const INVALID_TOKEN = 'INVALID_TOKEN';

// Query strings
export const ADVANCED_FILTERS_KEY_PARAM = 'advanced_filters';
export const BRAND_KEY_PARAM = 'brand';
export const CATEGORY_KEY_PARAM = 'category';
export const CODE_KEY_PARAM = 'code';
export const COMPANY_KEY_PARAM = 'company';
export const CREATED_AT_KEY_PARAM = 'created_at';
export const CREATION_DATE_KEY_PARAM = 'creation_date';
export const DIRECTION_KEY_PARAM = 'direction';
export const FILTERS_KEY_PARAM = 'filters';
export const KQNUM_KEY_PARAM = 'KQNUM';
export const KQSEQ_KEY_PARAM = 'KQSEQ';
export const LABEL_KEY_PARAM = 'label';
export const MARGIN_KEY_PARAM = 'margin';
export const MONTHS_KEY_PARAM = 'months';
export const MONTH_FROM_KEY_PARAM = 'month_from';
export const MONTH_TO_KEY_PARAM = 'month_to';
export const DATE_FROM_KEY_PARAM = 'date_from';
export const DATE_TO_KEY_PARAM = 'date_to';
export const NAME_KEY_PARAM = 'name';
export const ORDER_KEY_PARAM = 'order';
export const ORDER_SEARCH_TERM_KEY_PARAM = 'order_search_term';
export const PAGE_KEY_PARAM = 'page';
export const POSITION_KEY_PARAM = 'position';
export const PREDICTIONS_KEY_PARAM = 'predictions';
export const PRINT_KEY_PARAM = 'print';
export const QUICK_ENTRY_TERM = 'quick_entry_term';
export const QUOTE_SEARCH_TERM_KEY_PARAM = 'quote_search_term';
export const REDIRECT_URI_KEY_PARAM = 'redirect_uri';
export const REFRESH_TOKEN_KEY_PARAM = 'refresh_token';
export const REL_KEY_PARAM = 'rel';
export const SCORE_KEY_PARAM = 'score';
export const SEARCH_TERM_KEY_PARAM = 'search_term';
export const SID_KEY_PARAM = 'SID';
export const STATUS_KEY_PARAM = 'status';
export const SYNC_KEY_PARAM = 'sync';
export const TERM_KEY_PARAM = 'term';
export const TOKEN_KEY_PARAM = 'token';
export const TYPE_KEY_PARAM = 'type';
export const WAREHOUSE_KEY_PARAM = 'warehouse';

export const ITEM_TYPE_PRODUCT = 'product';
export const ITEM_TYPE_TEXT = 'text';
export const ITEM_TYPE_FEE = 'fee';
export const ITEM_TYPE_COMMENT = 'comment';

// Periods
export const PERIOD_3_MONTHS = {
  label: '3 mois',
  value: 3,
};
export const PERIOD_6_MONTHS = {
  label: '6 mois',
  value: 6,
};
export const PERIOD_9_MONTHS = {
  label: '9 mois',
  value: 9,
};
export const PERIOD_12_MONTHS = {
  label: '12 mois',
  value: 12,
};

// Products list render types
export const RENDER_TYPE_LIST = 'list';
export const RENDER_TYPE_MOSAIC = 'mosaic';
export const RENDER_TYPE_SEARCH = 'search';
export const RENDER_TYPE_TABLE = 'table';

// Cart items list render types
export const RENDER_TYPE_ITEM = 'item';
export const RENDER_TYPE_DIFF = 'diff';

export const STRINGIFIED_EMPTY_OBJECT = '{}';
export const ASC = 'asc';
export const DESC = 'desc';
export const PREV = 'prev';
export const NEXT = 'next';
export const SEARCH_TERM_MIN = 2;
export const FILTER_SEARCH_TERM_MIN = 3;
export const TRUE_STRING = 'true';
export const INFINITE_SCROLL_LOAD_PAGES = 5;
export const SEARCH_NODE_ID = 'search';
export const PRODUCT_PUSH_POSITION = 5;
export const QUANTITY_LIMIT = 9999;
export const QUANTITY_STEP_MINI = 0.01;
export const QUANTITY_SPLIT_PACKAGING_INCREMENT = 1;
export const YOUTUBE_REL_VALUE = 0;
export const FILTERS_ACCORDION_MIN_VALUES_DISPLAYED = 6;
export const FILTERS_CATEGORIES_MIN_VALUES_DISPLAYED = 10;
export const TOP_PRODUCTS_DEFAULT_PERIOD = 12;
export const PRODUCTS_LAST_VIEWED_LIMIT = 4;
export const PRODUCTS_PROMO_LIMIT = 4;
export const SIMILAR_PRODUCTS_LAST_VIEWED_LIMIT = 9;
export const PRODUCTS_PREDICTIONS_LIMIT = 4;
export const PRODUCTS_CONFIGURABLES_LIMIT = 2;
export const PARTNERS_LIMIT_HOME = 15;
export const PARTNERS_LIMIT_EBAT = 100;
export const ECATALOGS_LIMIT = 50;
export const ORDERS_HOME_LIMIT = 3;
export const PARSE_INT_RADIX = 10;
export const PRODUCT_NAME_MAX_LENGTH = 35;
export const SEARCH_LATEST_LIMIT = 5;
export const NUMBER_REGEX = /^-?\d+(\.\d+)?$/;
export const NUMERIC_REGEX = /#(\d+)/;
export const PASSWORD_REGEX = /^.*(?=.{13,})(?=.+[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9]).*$/;
export const SCANBAT_REGEX = /^(\/|\bhttps?:\/\/\S+\/)scanbat\/login\?warehouse=[A-Z0-9]+&company=[A-Z0-9]+$/;
export const VIMEO_REGEX = /\/\/(?:www\.)?vimeo.com\/([0-9a-z\-_]+)/i;
export const YOUTUBE_REGEX = /^.*(youtu\.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;

// Session storage index keys
export const SESSION_STORAGE_ACCESS_TOKEN = 'access_token';
export const SESSION_STORAGE_BREADCRUMB = 'breadcrumb';
export const SESSION_STORAGE_CHECKOUT_ORDER = 'checkout_order';
export const SESSION_STORAGE_CURRENT_PATH = 'current_path';
export const SESSION_STORAGE_DISABLED_IA = 'disabled_ia';
export const SESSION_STORAGE_FILTERS_OPEN = 'filters_open';
export const SESSION_STORAGE_HIDDEN_BANNER = 'hidden_banner';
export const SESSION_STORAGE_MIRAGE = 'mirage';
export const SESSION_STORAGE_PREV_PATH = 'prev_path';
export const SESSION_STORAGE_REFRESH_TOKEN = 'refresh_token';
export const SESSION_STORAGE_RENDER_TYPE = 'render_type';
export const SESSION_STORAGE_SCANBAT_COMPANY = 'scanbat_company';
export const SESSION_STORAGE_SCANBAT_WAREHOUSE = 'scanbat_warehouse';
export const SESSION_STORAGE_USER_SELECTED = 'user_selected';
export const SESSION_STORAGE_ZERG_RUSH = 'zerg_rush';

// Endpoint keys //TODO add ENDPOINT_XXX like ROUTE_ACCOUNT_INFORMATIONS
export const ENDPOINT_ROOT = '/api/v1';
export const ENDPOINT_ROOT_LOGIN = '/api/login';
export const ENDPOINT_ADDRESSES = 'addresses';
export const ENDPOINT_BILLING_ADDRESS = `${ENDPOINT_ADDRESSES}/billing-address`;
export const ENDPOINT_CARTS = 'carts';
export const ENDPOINT_CARTS_UNIQUE = `${ENDPOINT_CARTS}/unique`;
export const ENDPOINT_CHECKOUT = 'checkout';
export const ENDPOINT_CHECKOUT_CONFIRM = `${ENDPOINT_CHECKOUT}/confirm`;
export const ENDPOINT_COMPANIES = 'companies';
export const ENDPOINT_COUNTRIES = 'countries';
export const ENDPOINT_CUSTOMERS = 'customers';
export const ENDPOINT_CUSTOMERS_CONTACTS = `${ENDPOINT_CUSTOMERS}/contacts`;
export const ENDPOINT_CUSTOMERS_CURRENT = `${ENDPOINT_CUSTOMERS}/current`;
export const ENDPOINT_CUSTOMERS_CURRENT_INFO = `${ENDPOINT_CUSTOMERS_CURRENT}/info`;
export const ENDPOINT_DEFAULT_ADDRESS = `${ENDPOINT_ADDRESSES}/default`;
export const ENDPOINT_DEFAULT_CART = `${ENDPOINT_CARTS}/default`;
export const ENDPOINT_DELIVERY_NOTES = 'delivery-notes';
export const ENDPOINT_DELIVERY_NOTES_FORMATS = `${ENDPOINT_DELIVERY_NOTES}/formats`;
export const ENDPOINT_DISCOUNT_CLASSES = 'discount-classes';
export const ENDPOINT_DOCUMENTS = 'documents';
export const ENDPOINT_DOCUMENTS_CONTENT = `${ENDPOINT_DOCUMENTS}/content`;
export const ENDPOINT_ECATALOGS = 'e-catalogs';
export const ENDPOINT_EMPTY = 'empty';
export const ENDPOINT_FAQ = 'faq';
export const ENDPOINT_FILES = 'files';
export const ENDPOINT_FILES_AVATAR = `${ENDPOINT_FILES}/avatar`;
export const ENDPOINT_FILES_LOGO = `${ENDPOINT_FILES}/logo`;
export const ENDPOINT_FORMAT = 'format';
export const ENDPOINT_GENERAL_OPTIONS = 'general-options';
export const ENDPOINT_INVOICES = 'invoices';
export const ENDPOINT_ITEMS = 'items';
export const ENDPOINT_MAINTENANCE_STATUS = 'maintenance-status';
export const ENDPOINT_MESSAGES_BANNER = 'messages-banner';
export const ENDPOINT_MOVE = 'move';
export const ENDPOINT_MOVE_ITEMS = 'move-items';
export const ENDPOINT_NODES = 'nodes';
export const ENDPOINT_NODES_JOB_TREE = `${ENDPOINT_NODES}/job-tree`;
export const ENDPOINT_NODES_SIBLINGS = `${ENDPOINT_NODES}/siblings`;
export const ENDPOINT_NODES_TREE = `${ENDPOINT_NODES}/tree`;
export const ENDPOINT_NOTIFICATIONS = 'notifications';
export const ENDPOINT_NOTIFY = 'notify';
export const ENDPOINT_ORDER = 'order';
export const ENDPOINT_ORDERS = 'orders';
export const ENDPOINT_ORDERS_MINIMALS = `${ENDPOINT_ORDERS}/minimals`;
export const ENDPOINT_ORDERS_SHIPPING_TYPES = `${ENDPOINT_ORDERS}/shipping-types`;
export const ENDPOINT_ORDERS_WAREHOUSES = `${ENDPOINT_ORDERS}/warehouses`;
export const ENDPOINT_PARTNERS = 'partners';
export const ENDPOINT_PREDICTIONS = 'predictions';
export const ENDPOINT_PRICE_AND_STOCK = 'price-and-stock';
export const ENDPOINT_PRICE_FILE = 'price-file';
export const ENDPOINT_PRODUCT = 'product';
export const ENDPOINT_PRODUCTS = 'products';
export const ENDPOINT_PRODUCTS_LAST_VIEWED = `${ENDPOINT_PRODUCTS}/last-viewed`;
export const ENDPOINT_PRODUCTS_PROMO = `${ENDPOINT_NODES}/dynamic/discount`;
export const ENDPOINT_PRODUCTS_QUICK_ENTRY = `${ENDPOINT_PRODUCTS}/quick-entry`;
export const ENDPOINT_PROMOTION_BLOCK = 'promotion-block';
export const ENDPOINT_PROMOTION_BLOCK_LIST = `${ENDPOINT_PROMOTION_BLOCK}/list`;
export const ENDPOINT_QUOTES = 'quotes';
export const ENDPOINT_QUOTES_DRAFT = `${ENDPOINT_QUOTES}/draft`;
export const ENDPOINT_QUOTES_INSTALLER = `${ENDPOINT_QUOTES}/installer`;
export const ENDPOINT_READ_ARCHIVE = `${ENDPOINT_NOTIFICATIONS}/read-archive`;
export const ENDPOINT_REFUNDS = 'refunds';
export const ENDPOINT_REJECT = 'reject';
export const ENDPOINT_REVERSE_ADD = 'reverse-add';
export const ENDPOINT_ROLES = 'roles';
export const ENDPOINT_SCANBAT_CHECKOUT_CONFIRM = '/scanbat/checkout/confirm';
export const ENDPOINT_SCAN_SELF_SERVICE_PRODUCT = '/scanselfservice/product';
export const ENDPOINT_SEARCH = 'search';
export const ENDPOINT_SEARCH_AUTOCOMPLETE = `${ENDPOINT_SEARCH}/autocomplete`;
export const ENDPOINT_SEARCH_LATEST = `${ENDPOINT_SEARCH}/latest`;
export const ENDPOINT_SHIPPING_REQUEST = 'shipping-request';
export const ENDPOINT_SIMILAR = 'similar';
export const ENDPOINT_SLIDES = 'slides';
export const ENDPOINT_SUPPLIERS = 'suppliers';
export const ENDPOINT_SUPPLIERS_HOME = `${ENDPOINT_SUPPLIERS}/homepage`;
export const ENDPOINT_TOGGLE_MULTI_SELECTION = 'toggle-multi-selection';
export const ENDPOINT_TOGGLE_SELECTION = 'toggle-selection';
export const ENDPOINT_TOP_PRODUCTS = 'top-products';
export const ENDPOINT_UPDATE_COOKIE_ACCEPTANCE = 'update-cookie-acceptance';
export const ENDPOINT_UPDATE_VAT_OR_COEFFICIENT = 'update-vat-or-coefficient';
export const ENDPOINT_USER_TOKEN = 'user-token';
export const ENDPOINT_USER_TOKEN_VALIDATE = `${ENDPOINT_USER_TOKEN}/validate`;
export const ENDPOINT_USERS = 'users';
export const ENDPOINT_USERS_CHOOSE_PASSWORD = `${ENDPOINT_USERS}/choose-password`;
export const ENDPOINT_USERS_CURRENT = `${ENDPOINT_USERS}/current`;
export const ENDPOINT_USERS_CURRENT_INFO = `${ENDPOINT_USERS_CURRENT}/info`;
export const ENDPOINT_USERS_CURRENT_SESSION_ID = `${ENDPOINT_USERS_CURRENT}/session-id`;
export const ENDPOINT_USERS_CURRENT_UPDATE = `${ENDPOINT_USERS_CURRENT}/update`;
export const ENDPOINT_USERS_CURRENT_UPDATE_PASSWORD = `${ENDPOINT_USERS_CURRENT}/update-password`;
export const ENDPOINT_USERS_CURRENT_UPDATE_SHOW_NET_PRICE = `${ENDPOINT_USERS_CURRENT}/update-show-net-price`;
export const ENDPOINT_USERS_FILES = `${ENDPOINT_USERS}/files`;
export const ENDPOINT_USERS_FILES_AVATAR = `${ENDPOINT_USERS_FILES}/avatar`;
export const ENDPOINT_USERS_FILES_LOGO = `${ENDPOINT_USERS_FILES}/logo`;
export const ENDPOINT_USERS_REGISTER = `${ENDPOINT_USERS}/register`;
export const ENDPOINT_USERS_RENEW_PASSWORD = `${ENDPOINT_USERS}/renew-password`;
export const ENDPOINT_USERS_RENEW_PASSWORD_RESET = `${ENDPOINT_USERS_RENEW_PASSWORD}/reset`;
export const ENDPOINT_USERS_RENEW_PASSWORD_SEND_LINK = `${ENDPOINT_USERS_RENEW_PASSWORD}/send-link`;
export const ENDPOINT_USERS_SCANBAT_REGISTER = `${ENDPOINT_USERS}/scan-bat/register`;
export const ENDPOINT_VALIDATE = 'validate';
export const ENDPOINT_WAREHOUSES = 'warehouses';
export const ENDPOINT_WISHLISTS = 'wishlists';
export const ENDPOINT_WISHLISTS_CATEGORIES = `${ENDPOINT_WISHLISTS}/categories`;
export const ENDPOINT_WISHLISTS_TREE = `${ENDPOINT_WISHLISTS}/get-tree`;
export const ENDPOINT_WORKSITES = 'worksites';
// Routes keys
export const ROUTE_HOME = '/';
export const ROUTE_404 = '/404';
export const ROUTE_ACCOUNT = '/account';
export const ROUTE_ACCOUNT_DOCUMENTATION = `${ROUTE_ACCOUNT}/documentation`;
export const ROUTE_ACCOUNT_INFORMATIONS = `${ROUTE_ACCOUNT}/informations`;
export const ROUTE_ACCOUNT_LOYALTY_SPACE = `${ROUTE_ACCOUNT}/loyalty-space`;
export const ROUTE_ACCOUNT_TOP_PRODUCTS = `${ROUTE_ACCOUNT}/top-products`;
export const ROUTE_ACCOUNT_WORKSITES = `${ROUTE_ACCOUNT}/worksites`;
export const ROUTE_APP_CRAFTER = '/app-crafter';
export const ROUTE_APP_CRAFTER_CHOOSE_PASSWORD = `${ROUTE_APP_CRAFTER}/choose-password`;
export const ROUTE_APP_CRAFTER_CONFIRM = `${ROUTE_APP_CRAFTER}/confirm`;
export const ROUTE_APP_CRAFTER_EMAIL_SENT = `${ROUTE_APP_CRAFTER}/email-sent`;
export const ROUTE_APP_CRAFTER_RENEW_PASSWORD = `${ROUTE_APP_CRAFTER}/renew-password`;
export const ROUTE_AUTO_LOGIN = '/auto-login';
export const ROUTE_CARTS = '/cart';
export const ROUTE_CARTS_CHECKOUT = `${ROUTE_CARTS}/checkout`;
export const ROUTE_CARTS_CHECKOUT_CONFIRM = `${ROUTE_CARTS_CHECKOUT}/confirm`;
export const ROUTE_CHECKOUT = '/checkout';
export const ROUTE_CHECKOUT_CONFIRM = `${ROUTE_CHECKOUT}/confirm`;
export const ROUTE_CHOOSE_PASSWORD = '/choose-password';
export const ROUTE_CONTACT = '/contact';
export const ROUTE_DISCOUNT = '/discount-class';
export const ROUTE_ECATALOGS = '/e-catalogs';
export const ROUTE_EDIT = '/edit';
export const ROUTE_FAQ = '/faq';
export const ROUTE_FORGOT_PASSWORD = '/forgot-password';
export const ROUTE_LEGALS = '/legals';
export const ROUTE_LOGIN = '/login';
export const ROUTE_LOGOUT = '/logout';
export const ROUTE_NODES = '/nodes';
export const ROUTE_NODES_CATEGORY = `${ROUTE_NODES}/category`;
export const ROUTE_NODES_CLEARANCE = `${ROUTE_NODES}/clearance`;
export const ROUTE_NODES_DISCOUNT = `${ROUTE_NODES}/discount`;
export const ROUTE_NODES_DISCOUNT_CLASS = `${ROUTE_NODES}/discount-class`;
export const ROUTE_NODES_EXCLUSIVITY = `${ROUTE_NODES}/exclusivity`;
export const ROUTE_NODES_SUPPLIER = `${ROUTE_NODES}/supplier`;
export const ROUTE_ORDERS = '/orders';
export const ROUTE_PARTNERS = '/partners';
export const ROUTE_PRIVACY = '/privacy';
export const ROUTE_PRODUCT = '/product';
export const ROUTE_QUOTES = '/quotes'; // Caution : call in common addToContext
export const ROUTE_REGISTER = '/register';
export const ROUTE_RENEW_PASSWORD = '/renew-password';
export const ROUTE_SCANBAT = '/scanbat';
export const ROUTE_SCANBAT_PRODUCT = `${ROUTE_SCANBAT}/product`;
export const ROUTE_SCANBAT_CHECKOUT = `${ROUTE_SCANBAT}/checkout`;
export const ROUTE_SCANBAT_CHECKOUT_CONFIRM = `${ROUTE_SCANBAT_CHECKOUT}/confirm`;
export const ROUTE_SCANBAT_FORGOT_PASSWORD = `${ROUTE_SCANBAT}/forgot-password`;
export const ROUTE_SCANBAT_LOGIN = `${ROUTE_SCANBAT}/login`;
export const ROUTE_SCAN_SELF_SERVICE = '/libre-service';
export const ROUTE_SEARCH = '/search';
export const ROUTE_STANDALONE = '/standalone';
export const ROUTE_STANDALONE_BARCODE = `${ROUTE_STANDALONE}/barcode`;
export const ROUTE_STANDALONE_CART = `${ROUTE_STANDALONE}/cart`;
export const ROUTE_STANDALONE_CART_ADD = `${ROUTE_STANDALONE_CART}/add`;
export const ROUTE_STANDALONE_LOGIN = `${ROUTE_STANDALONE}/login`;
export const ROUTE_V2_TEST = '/v2-test';
export const ROUTE_VALIDATE = '/validate';
export const ROUTE_WISHLISTS = '/wishlists';
export const ENDPOINT_MERCURE_HUB_TOKEN = `${ENDPOINT_USERS}/current/mercure/token`;

export const ROUTE_OLD_EBAT = 'https://old.ebatpro.fr/c';
export const ROUTE_OLD_EBAT_CART = `${ROUTE_OLD_EBAT}/panierValidation.html`;
export const ROUTE_OLD_EBAT_QUOTE = `${ROUTE_OLD_EBAT}/detailsDevis.html`;

// External Links
export const LINK_TARIFEO = 'https://www.tarifeo.com';
export const LINK_MATERIAUX = 'https://www.lesmateriaux.fr';
export const LINK_AUBADE = 'https://www.espace-aubade.fr';
export const LINK_SVG_MENU = 'https://media.reflet.dev/lesmateriaux/category/';
export const LINK_EDENRED = 'https://www.edenred.fr/kadeos';
export const LINK_STORE_AUBADE = 'https://eqrco.de/a/GZjeFG';

// React query cache and stale
export const REACT_QUERY_CACHE_TIME = 1000 * 60 * 5; // 5 minutes
export const REACT_QUERY_STALE_TIME = 0; // 0 minute

// AddTo types
export const ITEM_TYPES = {
  cart: 'cart',
  carts: 'carts',
  draft: 'draft',
  installer: 'installer',
  order: 'order',
  quote: 'quote',
  quotes: 'quotes',
  wishlist: 'wishlist',
  wishlists: 'wishlists',
};

export const IMPORT_TYPE = {
  cart: 'cart',
  wishlist: 'wishlist',
};

// Quote types
export const QUOTE_TYPES = {
  key: {
    d: 'draft',
    q: 'default',
    i: 'installer',
  },
  letter: {
    draft: 'd',
    default: 'q',
    installer: 'i',
  },
};

// Quote types
export const LINKED_QUOTE_TYPES = {
  key: {
    q: 'SOUS MANDATS',
    i: 'DEVIS INSTALLATEUR',
  },
  letter: {
    default: 'q',
    installer: 'i',
  },
};

// Quote installer types
export const QUOTE_INSTALLER_TYPES = {
  PRODUCT: ITEM_TYPE_PRODUCT,
  COMMENT: ITEM_TYPE_COMMENT,
};

// Quote default types
export const QUOTE_DEFAULT_TYPES = {
  PRODUCT: ITEM_TYPE_PRODUCT,
  TEXT: ITEM_TYPE_TEXT,
  FEE: ITEM_TYPE_FEE,
};

// Quote types
export const QUOTE_TYPES_KEY = {};

// Roles
export const ROLES = {
  CUSTOMER_ADMIN: 'CUSTOMER_ADMIN',
  CUSTOMER_BLOCKED: 'CUSTOMER_BLOCKED',
  CUSTOMER_BUYER: 'CUSTOMER_BUYER',
  CUSTOMER_OBSERVER: 'CUSTOMER_OBSERVER',
  CUSTOMER_PRESENTER: 'CUSTOMER_PRESENTER',
  CUSTOMER_GUEST: 'CUSTOMER_GUEST',
};

// Notifications Types
export const NOTIFICATIONS_TYPES = {
  READ: 'read',
  ARCHIVE: 'archive',
};

// Entity Type
export const NOTIFICATIONS_ENTITY_TYPE = {
  QUOTE: 'quote',
  ORDER: 'order',
  ALL: 'all',
};

// Order status
export const ORDER_STATUS = {
  BLOCKED: 'blocked',
  IN_PROCCESS: 'in-process',
  INVOICED: 'invoiced',
  PARTIALLY_PICKED_UP_OR_INVOICED: 'partially-picked-up-or-invoiced',
  PARTIALLY_SHIPPED_OR_INVOICED: 'partially-shipped-or-invoiced',
  PICKED_UP: 'picked-up',
  PROCESS_SEE_DETAILS: 'process-see-details',
  SAVED: 'saved',
  SHIPPED: 'shipped',
};

// Order status groups
export const ORDER_STATUS_GROUPS = {
  IN_PROGRESS: 'en-cours',
  DELIVERED_NOT_INVOICED: 'livree-non-facturees',
  INVOICED: 'facturees',
};

// Order actions
export const ORDER_ACTIONS = {
  DELIVERY_NOTES: 'delivery_notes',
  PROVISION_REQUEST: 'provision_request',
  SHIPPING_REQUEST: 'shipping_request',
};

// Quote installer status
export const QUOTE_INSTALLER_STATUS = {
  IN_PROGRESS: 'in-progress',
  GENERATE: 'generate',
};

// Quote status
export const QUOTE_STATUS = {
  ACHIEVED: 'achieved',
  COUNTEROFFER: 'countroffer',
  EDITED: 'edited',
  ENTRY: 'entry',
  INSTALLER: 'installer',
  LOST: 'lost',
  PARTIALLY_ACHIEVED: 'partially-achieved',
  REQUEST: 'request',
};

// Quote actions
export const QUOTE_ACTIONS = {
  UPDATE: 'update',
  TO_ORDER: 'to_order',
  SEND_REQUEST: 'send_request',
};

export const DELIVERED_QUANTITY_LABEL = {
  DELETED: 'Supprimé',
  PARTIAL: 'Partiel',
  RETURNED: 'Retournée',
  SOLD: 'Soldée',
};

export const PRICE_AND_STOCK_PATHS = ['product', 'node', 'cart'];

export const FILE_TYPES = [
  {
    key: '.csv',
    value: 'CSV',
  },
  {
    key: '.xls',
    value: 'XLS',
  },
  {
    key: '.txt',
    value: 'TXT',
  },
];

export const PDF_FILE = 'pdf';
export const CSV_FILE = 'csv';
export const XLS_FILE = 'xls';
export const XLSX_FILE = 'xlsx';
export const JPG_FILE = 'jpg';
export const JPEG_FILE = 'jpeg';
export const PNG_FILE = 'png';

export const RESPONSE_TYPE = {
  BLOB: 'blob',
};

export const STOCK_STATUS = {
  COMMANDE: 'COMMANDE',
  DELAI: 'DELAI',
  DISPO: 'DISPO',
  NODIS: 'NODIS',
};

export const MEDIA_TYPES = {
  IMAGE: 'image',
  VIDEO: 'video',
};

export const SHIPPING_TYPES = {
  shipping_collect: 'E',
  shipping_delivery: 'L',
  shipping_drive: 'D',
};

export const SHIPPING_VALUE = {
  pickup: 'pickup',
  drive: 'drive',
};

export const SHIPPING_ADDRESS_TYPES = {
  personal: 'personal',
  worksite: 'worksite',
  warehouse: 'warehouse',
};

export const MOVE_TYPE = {
  UP: 'move up',
  DOWN: 'move down',
};

export const GENERAL_OPTIONS = {
  DELIVERY: 'delivery',
  SITE: 'site',
};

export const ALTERNATIVE_TYPE = {
  SUBSTITUTE: 'substitute',
  DIFF: 'diff',
};

export const COMPANIES_LIST = {
  AD: {
    code: 'AD',
    name: 'Andrez-Brajon / Dupont-Est',
    website: 'https://www.ad-by-aubade.fr',
  },
  BJ: {
    code: 'BJ',
    name: 'Bloc et Job',
    website: 'https://www.lesmateriaux.fr/magasins/bloc-et-job',
  },
  BM: {
    code: 'BM',
    name: 'Batimantes',
    website: 'https://www.batimantes.fr',
  },
  CA: {
    code: 'CA',
    name: 'Comtat et Allardet',
    website: 'https://www.espace-aubade.fr/recherche?recherche=comtat',
  },
  CF: {
    code: 'CF',
    name: 'Comafranc',
    website: 'https://www.comafranc.fr',
  },
  CG: {
    code: 'CG',
    name: 'Comptoir des Fers',
    website: 'https://www.comptoirdesfers.com',
  },
  CH: {
    code: 'CH',
    name: 'Chadapaux',
    website: 'https://www.chadapaux.fr',
  },
  CM: {
    code: 'CM',
    name: 'CMPM',
    website: 'https://www.lesmateriaux.fr',
  },
  CO: {
    code: 'CO',
    name: 'Comet',
    website: 'https://www.comet-sas.fr',
  },
  CR: {
    code: 'CR',
    name: 'CMR',
    website: 'https://www.espace-cmr.com',
  },
  GU: {
    code: 'GU',
    name: 'Guiraud',
    website: 'https://www.guiraud-sa.com',
  },
  MA: {
    code: 'MA',
    name: 'Maillard',
    website: 'https://www.maillard.fr',
  },
  ME: {
    code: 'ME',
    name: 'Mestre',
    website: 'https://www.mestre.fr',
  },
  ML: {
    code: 'ML',
    name: 'Malrieu',
    website: 'https://www.malrieu.fr',
  },
  MO: {
    code: 'MO',
    name: 'Moy',
    website: 'https://www.espace-aubade.fr/recherche?recherche=moy',
  },
  MQ: {
    code: 'MQ',
    name: 'Méquisa',
    website: 'https://www.mequisa.fr',
  },
  ND: {
    code: 'ND',
    name: 'Nouveaux Docks',
    website: 'https://www.lesmateriaux.fr',
  },
  NI: {
    code: 'NI',
    name: 'Nicodeme Aubade',
    website: 'https://www.nicodeme.com',
  },
  PO: {
    code: 'PO',
    name: 'Pompac',
    website: 'https://www.pompac.fr',
  },
  PS: {
    code: 'PS',
    name: 'Pagot Savoie',
    website: 'https://www.pagot-savoie.fr',
  },
  RO: {
    code: 'RO',
    name: 'Rouenel',
    website: 'https://www.espace-aubade.fr/recherche?recherche=rouenel',
  },
  SA: {
    code: 'SA',
    name: 'Sanisitt',
    website: 'https://www.sanisitt.fr',
  },
  SF: {
    code: 'SF',
    name: 'SFCP',
    website: 'https://www.sfcp-espace-aubade.fr',
  },
  SI: {
    code: 'SI',
    name: 'Siehr',
    website: 'https://www.siehr.fr',
  },
  SL: {
    code: 'SL',
    name: 'Comptoir Sanitaire',
    website: 'https://www.comptoir-sanitaire.com',
  },
  SN: {
    code: 'SN',
    name: 'Schmitt-Ney',
    website: 'https://www.schmitt-ney.fr',
  },
  SO: {
    code: 'SO',
    name: 'Somatem',
    website: 'https://www.somatem.fr',
  },
  ZC: {
    code: 'ZC',
    name: 'Comptoir des Fers Test',
    website: 'https://www.comptoirdesfers.com',
  },
  ZF: {
    code: 'ZF',
    name: 'Comafranc Test',
    website: 'https://www.comafranc.fr',
  },
  ZN: {
    code: 'ZN',
    name: 'Nouveaux Docks Test',
    website: 'https://www.lesmateriaux.fr',
  },
  ZQ: {
    code: 'ZQ',
    name: 'Siehr Test',
    website: 'https://www.siehr.fr',
  },
};

export const DIFF_FLAG = {
  color: '#e50019',
  label: 'diff',
  slug: 'diff',
};

export const VARIANT_FLAG = {
  color: '#2d2e2e',
  label: 'variant',
  slug: 'variant',
};

export const OPTION_FLAG = {
  color: '#0020ff',
  label: 'option',
  slug: 'option',
};

export const FILTERS_CODE = {
  INVOICE: 'invoice',
  PERIOD: 'period',
  STATUS: 'status',
  TYPE: 'type',
};

export const DIRECTION = {
  HORIZONTAL: 'h',
  VERTICAL: 'v',
};

export const EVENT_CODE = {
  SPACE: 'Space',
};

export const OFFERS_CATEGORY = {
  CLEARANCE: 'clearance',
  DISCOUNT: 'discount',
  EXCLUSIVITY: 'exclusivity',
};

export const AVATAR_COLORS = [
  '#77C49B',
  '#7384FA',
  '#73CBFA',
  '#BC73FA',
  '#F295D5',
  '#FF6565',
  '#FFDA41',
  '#FFBA4B',
  '#B28686',
  '#B2B2B2',
];

export const ACCOUNT_DETAILS_TYPES = {
  update: 'update',
  add_user: 'add_user',
};

export const SELECT_GROUP_TYPES = {
  DISABLE_EMPTY_ITEMS: 'DISABLE_EMPTY_ITEMS',
  ENABLE_ALL_ITEMS: 'ENABLE_ALL_ITEMS',
};

export const SOURCE = {
  CONFIGURATOR: 'configurator',
  DRAFT: 'draft',
  QUOTE: 'quote',
  QUOTE_INSTALLER: 'quote_installer',
  WISHLIST: 'wishlist',
  SEARCH: 'search',
  LAST_SEEN: 'last_seen',
  PRODUCT_LIST: 'product_list',
  PRODUCT_DETAIL: 'product_detail',
  E_CATALOG: 'e_catalog',
  PALMARES: 'palmares',
  QUICK_ENTRY: 'quick_entry',
  ORDER: 'order',
  PUSH_PRODUCT: 'push_product',
  SCANBAT: 'scanbat',
};

export const CHECKOUT_ADDRESSES_ACCORDION_LIMIT = 6;

export const NULL_VALUE = '{{null_value}}';
export const DURATION_CSS_PROPERTY = 'vars-duration';

export const PRODUCTS_API_NODE_ROOT_CATEGORY = 'category';
export const PRODUCTS_API_NODE_ROOT_SEARCH = 'search/search';
export const PRODUCTS_API_NODE_ROOT_OFFERS = 'dynamic';
export const PRODUCTS_API_NODE_ROOT_SUPPLIER = 'supplier';
export const PRODUCTS_API_NODE_ROOT_DISCOUNT = 'discount-class';
export const PAGE_PRODUCTS_CATEGORY = 'PAGE_PRODUCTS_CATEGORY';
export const PAGE_PRODUCTS_SEARCH = 'PAGE_PRODUCTS_SEARCH';
export const PAGE_PRODUCTS_OFFERS = 'PAGE_PRODUCTS_OFFERS';
export const PAGE_PRODUCTS_SUPPLIER = 'PAGE_PRODUCTS_SUPPLIER';
export const PAGE_PRODUCTS_DISCOUNT = 'PAGE_PRODUCTS_DISCOUNT';
export const PAGE_SECTION = 'PAGE_SECTION';

export const PRODUCTS_LOCAL_SORT_PUBLIC_PRICE = 'public_price';
export const PRODUCTS_LOCAL_SORT_STOCK = 'stock';
export const PRODUCTS_LOCAL_SORT_CUSTOMER_PRICE = 'customer_price';

export const DIAMETER = 'diametre';
